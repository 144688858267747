<template>
    <div>
      <vue-element-loading
        :active="appLoading"
        spinner="bar-fade-scale"
        color="#1976d2"
        size="128"
        :is-full-screen="false"
      />
      <v-snackbar v-model="showsnackbar" color="black" right>
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showsnackbar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout v-if="ServerError" wrap justify-center>
        <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
          <ServerError />
        </v-flex>
      </v-layout>
      <v-layout wrap v-else>
        <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
          <v-card>
            <v-card-title class="elevation-1">
              <span>Terms and Conditions</span>
              <v-spacer></v-spacer>
            </v-card-title>
            <div>
              <v-layout wrap>
                <v-flex pt-5 xl12 xs12 sm12 lg12 md12>
                  <div id="app">
                    <vue-editor v-model="details"></vue-editor>
                  </div>
                </v-flex>
              </v-layout>
              <v-layout pt-5 pb-5 wrap justify-end>
                <v-flex xs6 md2>
                  <v-btn
                    block
                    tile
                    color="green"
                    dark
                    :ripple="false"
                    style="text-transform: none"
                    depressed
                    @click="edit()"
                  >
                    Save Changes
                  </v-btn>
                </v-flex>
              </v-layout>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  import axios from "axios";
  import { VueEditor } from "vue2-editor";
  export default {
    components: {
      VueEditor,
    },
    data() {
      return {
        checkbox: true,
        ServerError: false,
        file: null,
        showsnackbar: false,
        editingitem: [],
        msg: null,
        pages: 0,
        details: null,
        widthOfCard: "250px",
        appLoading: false,
        currentPage: 1,
        totalData: 0,
        totalRows: 0,
        dialogDelete: false,
        search: "",
        dialog: false,
        editdialog: false,
        addslider: false,
        nameRules: [
          (v) => !!v || "Name is required",
          (v) => (v && v.length <= 15) || "Name must be less than 15 characters",
        ],
        Rules: [(value) => !!value || "Required."],
      };
    },
    beforeMount() {
      this.getData();
    },
    methods: {
      getData() {
        this.appLoading = true;
        axios({
          url: "/termsAndCondetions",
          method: "GET",
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.details = response.data.data.details;
          })
          .catch((err) => {
            this.appLoading = false;
            console.log(err);
          });
      },
      edit() {
        this.appLoading = true;
        var data = {};
        data["details"] = this.details;
        axios({
          method: "POST",
          url: "/edit/termsAndCondetions",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: data,
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              this.msg = "Edited Sucessfully";
              this.showsnackbar = true;
              this.dialog = false;
              this.getData();
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>